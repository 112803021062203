import { useState, useEffect } from 'react';
import { ethers } from 'ethers';

const ROLLUX_RPC_URL = 'https://rpc.rollux.com'; // Replace with the actual RPC URL of Rollux

const useWeb3 = () => {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [network, setNetwork] = useState(null);
  const [address, setAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const checkConnection = async () => {
      if (localStorage.getItem('isConnected') === 'true') {
        await connectToWeb3();
      }
    };
    checkConnection();
  }, []);

  const connectToWeb3 = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const ethersSigner = ethersProvider.getSigner();
        const ethersNetwork = await ethersProvider.getNetwork();
        const ethersAddress = await ethersSigner.getAddress();

        setProvider(ethersProvider);
        setSigner(ethersSigner);
        setNetwork(ethersNetwork);
        setAddress(ethersAddress);
        setIsConnected(true);
        localStorage.setItem('isConnected', 'true');

        window.ethereum.on('accountsChanged', async () => {
          const newAddress = await ethersSigner.getAddress();
          setAddress(newAddress);
        });

        window.ethereum.on('chainChanged', async (chainId) => {
          const newNetwork = await ethersProvider.getNetwork();
          setNetwork(newNetwork);
        });
      } catch (error) {
        console.error('Error connecting to web3:', error);
        setIsConnected(false);
        localStorage.setItem('isConnected', 'false');
      }
    } else {
      try {
        const ethersProvider = new ethers.providers.JsonRpcProvider(ROLLUX_RPC_URL);
        const ethersNetwork = await ethersProvider.getNetwork();

        setProvider(ethersProvider);
        setSigner(null); // No signer as this is a read-only provider
        setNetwork(ethersNetwork);
        setAddress(null);
        setIsConnected(true);
        localStorage.setItem('isConnected', 'true');
      } catch (error) {
        console.error('Error connecting to Rollux:', error);
        setIsConnected(false);
        localStorage.setItem('isConnected', 'false');
      }
    }
  };

  const disconnect = () => {
    setProvider(null);
    setSigner(null);
    setNetwork(null);
    setAddress(null);
    setIsConnected(false);
    localStorage.setItem('isConnected', 'false');
  };

  return { provider, signer, network, address, isConnected, connectToWeb3, disconnect };
};

export default useWeb3;
