import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes, HashRouter, useLocation } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import FileManager from "./pages/FileManager";

import Discover from "./pages/Discover";
import BurnTokens from "./pages/Burn";
import CrowdFunding from "./pages/Launchpad";
import NavigationBar from "./components/Navbar";
import { Web3Provider } from "./hooks/Web3Provider";
import ConnectButton from "./utils/ConnectButton";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import MultiStakingApp from "./pages/Staking";
import CampaignDetailPage from "./pages/CampaignDetail";
import EndedCampaign from "./pages/EndedCampaign";
import HelpComponent from "./pages/Faq";
import HelpPopup from "./pages/Help";
import FAQ from "./pages/Faq";
import CrowdfundingApplication from "./pages/Apply";
import Footer from "./pages/Footer";
import ComingSoon from "./pages/ComingSoon";
import Support from "./pages/Support";
import ScrollToTop from "./components/ScrollToTop";
import TokenMarketplace from "./pages/Marketplace";
import UserListings from "./pages/List";
import BottomNav from "./components/BottomNavbar/BottomNavbar";
import TicTacToe from "./game/TicTacToe";
import Airdrop from "./pages/Airdrop";
import FreeWorld from "./pages/Home";


const ROLLUX_CHAIN_ID ='0x89' //'0xdea8'//'0x23a';  // Rollux Mainnet chain ID
const ROLLUX_RPC_URL = 'https://polygon-mainnet.infura.io'//'https://rpc-tanenbaum.rollux.com'//'https://rpc.rollux.com';  // Rollux RPC URL




function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [network, setNetwork] = useState(null);
  const [connectedAddress, setConnectedAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);




  useEffect(() => {
    const previouslyConnected = localStorage.getItem('isConnected') === 'true';
    const address = localStorage.getItem('connectedAddress');
    if (previouslyConnected && address) {
      connectToWeb3();
    }
  }, []);

  const connectToWeb3 = async () => {
    if (window.ethereum) {
        try {
          // Check if already on the Rollux network
          const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
          if (currentChainId !== ROLLUX_CHAIN_ID) {
            // Switch to the Rollux network
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: ROLLUX_CHAIN_ID,
                chainName: 'Matic Mainnet',
                nativeCurrency: {
                  name: 'Matic',
                  symbol: 'MATIC',
                  decimals: 18,
                },
                rpcUrls: [ROLLUX_RPC_URL],
                blockExplorerUrls: ['https://polygonscan.com'],
              }],
            });
          }
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
        const ethersSigner = ethersProvider.getSigner();
        const ethersNetwork = await ethersProvider.getNetwork();
        const ethersAddress = await ethersSigner.getAddress();
        setProvider(ethersProvider);
        setSigner(ethersSigner);
        setNetwork(ethersNetwork);
        setConnectedAddress(ethersAddress);
        setIsConnected(true);
        localStorage.setItem('isConnected', 'true');
        localStorage.setItem('connectedAddress', ethersAddress);

        window.ethereum.on('accountsChanged', async () => {
          const newAddress = await ethersSigner.getAddress();
          setConnectedAddress(newAddress);
          localStorage.setItem('connectedAddress', newAddress);
        });

        window.ethereum.on('chainChanged', async (chainId) => {
          const newNetwork = await ethersProvider.getNetwork();
          setNetwork(newNetwork);
        });

      } catch (error) {
        console.error('Error connecting to web3:', error);
      }
    } else {
      console.error('MetaMask or compatible Ethereum provider not detected.');
    }
  };

  const disconnect = () => {
    setProvider(null);
    setSigner(null);
    setNetwork(null);
    setConnectedAddress('');
    setIsConnected(false);
    localStorage.removeItem('isConnected');
    localStorage.removeItem('connectedAddress');
  };


  return (
    <Web3Provider>

      <HashRouter>
      <ScrollToTop/>
     
          <Routes>
            <Route path="/" element={<FreeWorld connectToWeb3={connectToWeb3} disconnect={disconnect} connectedAddress={connectedAddress} provider={provider} signer={signer} />} />
      
          </Routes>
       
    
      </HashRouter>
    </Web3Provider>
  );
}

export default App;
