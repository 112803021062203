import React, { useState } from 'react';
 // Import your CSS file

import welcomeImage from '../image/welcome.jpg';
import woImage from '../image/wo.jpg';
import img4Image from '../image/img4.jpg';
import driveImage from '../image/drive.jpg';
import teamImage from '../image/team.jpg';
import airdropImage from '../image/airdrop.jpg';
import roadmapImage from '../image/roadmap.jpg';
import AirdropComponent from './Airdrop';

function FreeWorld({signer, provider, connectedAddress, connectToWeb3, disconnect}) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
  return (
    <div>
      <header>
        <nav className="navbar">
          <div className="contain">
            <h1 className="logo">Free World</h1>
          </div>
          <div className="btn">
          <button className="bkButton" onClick={openModal}>Airdrop</button>
          </div>
        </nav>
      </header>


      <div className="container">
        <div className="card" style={{ '--clr': '#009688' }}>
          <div className="img-box">
            <img src={welcomeImage} alt="Welcome to Free World" />
          </div>
          <div className="content">
            <h2>Welcome to Free World</h2>
            <p>
              Welcome aboard freedom fighter. No 1000X hype bullshit, we just want to build something unique for meme lovers and the crypto space.
            </p>
          </div>
        </div>
        <AirdropComponent connectToWeb3={connectToWeb3} disconnect={disconnect} connectedAddress={connectedAddress} provider={provider} signer={signer} />
        <div className="card" style={{ '--clr': '#009688' }}>
          <div className="img-box">
            <img src={woImage} alt="$WORLD" />
          </div>
          <div className="content">
            <h2>$WORLD</h2>
            <p>
              Free World ($WORLD) is a unique memecoin dedicated to creating an inclusive, open space for self-expression in the realms of culture, music, art, and identity. It's not about the hype; it's a community of "freedom fighters" who value creativity and acceptance.
            </p>
          </div>
        </div>
        <div className="card" style={{ '--clr': '#03A9F4' }}>
          <div className="img-box">
            <img src={img4Image} alt="$WORLD Distribution" />
          </div>
          <div className="content">
            <h2>$WORLD Distribution</h2>
            <p>
              Max Supply: 4 Trillion
            </p>
            <ul>
              <li>Airdrop & Presale: 1 Trillion $WORLD</li>
              <li>Liquidity: 1.8 Trillion $WORLD</li>
              <li>Community and ecosystem development: 700 Billion</li>
              <li>Team: 500 Billion</li>
            </ul>
          </div>
        </div>
        <div className="card" style={{ '--clr': '#03A9F4' }}>
          <div className="img-box">
            <img src={driveImage} alt="Our Drive" />
          </div>
          <div className="content">
            <h2>Our Drive</h2>
            <p>
              Our drive at 'Free World' is to see everyone happy and financially free. We believe blockchain technology is the key to achieving this. By creating a decentralized and inclusive community, we aim to empower individuals and promote financial independence.
            </p>
          </div>
        </div>
        <div className="card" style={{ '--clr': '#009688' }}>
          <div className="img-box">
            <img src={teamImage} alt="Meet the Team" />
          </div>
          <div className="content">
            <h2>Meet the Team</h2>
            <p>
              We're a small team of four passionate blockchain developers, committed to building something meaningful for the crypto community. But remember, every 'Free World' holder is part of our team. Together, we strive for one common goal: The Free World, The Big Bag.
            </p>
          </div>
        </div>
        <div className="card" style={{ '--clr': '#009688' }}>
          <div className="img-box">
            <img src={airdropImage} alt="Airdrop" />
          </div>
          <div className="content">
            <h2>Airdrop</h2>
            <p>
              Hey there! 🚀 It's a free world, so here's a little surprise for you: an airdrop of 'Free World' tokens! Do what you like with them – nobody's judging you! 😏 Make sure you've got enough to cover the transaction fee, or let us know if you need a hand. Enjoy!
            </p>
          </div>
        </div>
        <div className="card" style={{ '--clr': '#FF3E7F' }}>
          <div className="img-box">
            <img src={roadmapImage} alt="Roadmap" />
          </div>
          <div className="content">
            <h2>Roadmap</h2>
            <p>
              Let's not get our hopes up with promises we can't keep. At 'Free World,' our roadmap is simple: enjoy the journey and see where it takes us. Let's embrace the spontaneity and freedom to grow organically. After all, it's a free world!
            </p>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="container">
          <h2>Follow us</h2>
          <ul className="social-links">
            <li><a href="https://twitter.com/FreeWorldmeme" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
            <li><a href="https://t.me/FreeWorldCommunity" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram"></i></a></li>
            <li><a href="https://discord.gg/FreeWorld" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a></li>
          </ul>
          <p>&copy; 2024 Free World. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default FreeWorld;
