import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../asset/logo.png";
import Rollux from "../asset/logo1.png"; // Import your logo
import ConnectButton from "../utils/ConnectButton";

function NavigationBar({connectToWeb3, disconnect, connectedAddress}) {
 
 

  return (
    <div className="navbar">
      <Link to="/" className="logo-container">
        <img src={Logo} alt="Logo" className="logo2" />
      </Link>
   <ConnectButton connectToWeb3={connectToWeb3} disconnect={disconnect} connectedAddress={connectedAddress}/>
    </div>
  );
}

export default NavigationBar;
