// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase, ref, set, get, onValue } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCC5MPqwI4W5XkLQ6P6bXE_Usa1AljU6Y8",
  authDomain: "foxcraft-token.firebaseapp.com",
  projectId: "foxcraft-token",
  storageBucket: "foxcraft-token.appspot.com",
  messagingSenderId: "214656308223",
  appId: "1:214656308223:web:9f2ab402d1eab5d66c8dca"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const database = getDatabase(app);

export { app, firestore, storage,database, ref, set, get, onValue };
