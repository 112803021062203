import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import AirdropContract from '../contracts/Airdrop.json'; // Replace with your Airdrop contract ABI JSON

const AirdropComponent = ({ signer, provider, connectToWeb3, connectedAddress, connectButton, disconnect }) => {
    const [airdropContract, setAirdropContract] = useState(null);
    const [claimFee, setClaimFee] = useState('1.0');
    const [tokensPerClaim, setTokensPerClaim] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    


    const AirdropContractAddress = '0x5Cc0CA4ce6fD089a0E8a3689851Eda0A9b19bD11'
    useEffect(() => {
        const loadContract = async () => {
            try {
                setLoading(true);
                const contract = new ethers.Contract(
                    AirdropContractAddress, // Replace with your contract address
                    AirdropContract,
                    signer
                );
                setAirdropContract(contract);

              

               
            } catch (error) {
                console.error('Error loading contract:', error);
                setError(error.message || error.toString());
            } finally {
                setLoading(false);
            }
        };

        if (signer) {
            loadContract();
        }

    }, [signer]);

    const handleClaim = async () => {
      try {
          setLoading(true);
          
          // Check if claimFee is properly initialized
          if (!claimFee || claimFee === '0') {
              throw new Error('Claim fee is not properly initialized');
          }

          const contractAir = new ethers.Contract(
            AirdropContractAddress, // Replace with your contract address
            AirdropContract,
            signer
        );

          const tx = await contractAir.claim({
              value: ethers.utils.parseEther(claimFee.toString())
          });
          await tx.wait();
          alert('Tokens claimed successfully!');
        } catch (error) {
          console.error('Error claiming tokens:', error);
          setError(error.message || error.toString());
      } finally {
          setLoading(false);
      }
  };

  

 
   

    if (!connectToWeb3 || !connectedAddress) {
        return (
            <div>
                <p>Please connect to Web3.</p>
                <button onClick={connectToWeb3}>Connect</button>
            </div>
        );
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>Airdrop Component</h2>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <p>Connected Address: {connectedAddress}</p>
            <p>Claim Fee: 1 MATIC</p>
            <p>Tokens Per Claim: 100,000,000 $WORLD</p>
            <button onClick={handleClaim} disabled={!airdropContract}>
                Claim Tokens
            </button>
            <button onClick={disconnect}>Disconnect</button>
        </div>
    );
};

export default AirdropComponent;
